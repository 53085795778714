import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getClaimExpense(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/claim_expense`)
        return data
    },
    async isReadClaimExpense(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/claim_expense/is_read`)
        return data
    }
}
