<template>
    <div>
        <a-modal v-model:visible="show_create_company" title="เพิ่มข้อมูลบริษัท" :afterClose="afterClose" :footer="null">
            <a-form
                :model="create_client"
                name="create_client" autocomplete="off" layout="vertical"
                @finish="submit_create_client"
            >
                <a-row>
                    <a-col>
                        <a-form-item label="ลูกค้า/บริษัท" name="fullname" :rules="[{ required: true, message: `โปรดระบุชื่อบริษัท` }]">
                            <a-input v-model:value="create_client.fullname" placeholder="ชื่อบริษัท/ลูกค้า" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="เป็นลูกค้าของบริษัท" name="car_company_id">
                            <a-select v-model:value="create_client.car_company_id" placeholder="เลือกบริษัท">
                                <a-select-option v-for="{ id, fullname }, i in car_company_list" :key="i" :value="id">{{ fullname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-divider orientation="left">สาขาของบริษัท</a-divider>
                    
                    <a-col>
                        <a-card>
                            <a-table :pagination=false :data-source="branches" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }" :columns="[
                            {
                                title: 'ชื่อสาขา',
                                dataIndex: 'name',
                                key: 'name',
                                width: 100
                            },{
                                title: 'ที่อยู่สาขา',
                                dataIndex: 'address',
                                key: 'address',
                                width: 100
                            },{
                                title: 'เบอร์โทรสาขา',
                                dataIndex: 'phone_no',
                                key: 'phone_no',
                                width: 100
                            },
                        ]" />
                        </a-card>
                        
                        <a-form-item :span="24" style="text-align: right">
                            <a-button @click="show_create_branch = true" type="primary" size="large" style="margin: 4px">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Add</span>
                                เพิ่มสาขา
                            </a-button>
                            <a-drawer
                                v-model:visible="show_create_branch"
                                class="custom-class"
                                style="color: red"
                                title="เพิ่มสาขาบริษัท"
                                placement="right"
                            >
                            <a-form
                                :model="create_branch"
                                name="create_branch" autocomplete="off" layout="vertical"
                                @finish="submit_create_branch"
                            >
                                <a-row>
                                        <a-col>
                                            <a-form-item label="ชื่อสาขา" name="name" :rules="[{ required: true, message: `โปรดระบุชื่อสาขา` }]">
                                                    <a-input v-model:value="create_branch.name" placeholder="ชื่อสาขา" />
                                            </a-form-item>
                                        </a-col>

                                        <a-col>
                                            <a-form-item label="ที่อยู่สาขา" name="address" :rules="[{ required: true, message: `โปรดระบุที่อยู่สาขา` }]">
                                                    <a-input v-model:value="create_branch.address" placeholder="ที่อยู่สาขา" />
                                            </a-form-item>
                                        </a-col>

                                        <a-col>
                                            <a-form-item label="เบอร์โทรสาขา" name="phone_no" :rules="[{ required: true, message: `โปรดระบุเบอร์โทรสาขา` }]">
                                                <a-input v-model:value="create_branch.phone_no" placeholder="เบอร์โทรสาขา" />
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                    <a-row>
                                        <a-col>
                                            <a-button type="primary" html-type="submit" size="large" style="margin: 4px;text-align: right">
                                                <span class="material-symbols-outlined"
                                                    style="font-size: 16px;margin-right: 4px">Add</span>
                                                เพิ่มสาขา
                                            </a-button>
                                        </a-col>
                                    </a-row>
                            </a-form>
                            </a-drawer>
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="ประเภทกลุ่มลูกค้า">
                            <a-select v-model:value="create_client.tour_type_id" >
                                <a-select-option v-for="{ id, name } in tour_types" :key="id" >{{name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    
                    <!-- <a-col>
                        <a-form-item label="รหัสลูกค้า">
                            <a-input v-model:value="create_client.client_no" placeholder="รหัสลูกค้า" />
                        </a-form-item>
                    </a-col> -->

                    <a-col>
                        <a-form-item label="เลขที่ผู้เสียภาษี" name="tax_id" :rules="[{ required: true, message: `โปรดระบุเลขที่ผู้เสียภาษี` }]">
                            <a-input v-model:value="create_client.tax_id" placeholder="เลขที่ผู้เสียภาษี" />
                            <a style="margin: 4px" href="https://creden.co/creditscore/business/main.html">ตรวจสอบข้อมูลลูกค้าด้วย creden.co</a>
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="อีเมล">
                            <a-input v-model:value="create_client.email" placeholder="อีเมล" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="เบอร์โทรบริษัท">
                            <a-input v-model:value="create_client.phone_no" placeholder="เบอร์โทรบริษัท" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="เบอร์โทรบริษัทสำรอง">
                            <a-input v-model:value="create_client.phone_backup" placeholder="เบอร์โทรบริษัทสำรอง" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="เบอร์แฟกซ์">
                            <a-input v-model:value="create_client.fax_no" placeholder="เบอร์แฟกซ์" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="ที่อยู่">
                            <a-textarea v-model:value="create_client.address" placeholder="ที่อยู่" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="หมายเหตุ">
                            <a-textarea v-model:value="create_client.remark" placeholder="หมายเหตุ" />
                        </a-form-item>
                    </a-col>
                    
                </a-row>

                <a-row>
                    <a-col :span="12" style="text-align: right">
                        <a-button type="primary" style="display: flex;margin: 1.25rem 1rem;text-align: right;"
                           html-type="submit" size="large">
                            <template #icon>
                                <span class="material-symbols-outlined">Add</span>
                            </template>
                            เพิ่มข้อมูลบริษัท
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
            
        </a-modal>
    </div>
</template>

<script>
import CarCompanyService from '@/api/CarCompanyService.js'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'

export default {
    props: {
        show_create_company: Boolean,
        tour_types: Array
    },
    name: 'Create Company',
    data () {
        return {
            show_create_branch: null,
            branches: [],
            car_company_list: [],
            create_branch: {
                name: null,
                phone_no: null,
                address: null
            },
            create_client: {
                client_no: null,
                tour_type_id: null,
                car_company_id: null,
                fullname: null,
                tax_id: null,
                email: null,
                phone_no: null,
                phone_backup: null,
                fax_no: null,
                address: null,
                remark: null
            },
        }
    },
    methods: {
        submit_create_branch() {
            const default_value = { name: null, phone_no: null, address: null }
            this.branches.push(this.create_branch)
            this.create_branch = default_value
        },
        async create_client_branch (client_id) {
            const company_id = JSON.parse(localStorage.getItem('companyData')).id;
            this.branches.forEach(branch => {
                branch.client_id = client_id;
                branch.company_id = company_id;
            })
            
            const res = await SystemService.create_all('ClientBranch', { data: this.branches })

            return res
        },
        async submit_create_client () {
            const client = {
                client_no: this.create_client.client_no,
                tour_type_id: this.create_client.tour_type_id,
                company_id: JSON.parse(localStorage.getItem('companyData')).id,
                car_company_id: this.create_client.car_company_id,
                fullname: this.create_client.fullname,
                tax_id: this.create_client.tax_id,
                email: this.create_client.email,
                phone_no: this.create_client.phone_no,
                phone_no_backup: this.create_client.phone_no_backup,
                fax_no: this.create_client.fax_no,
                address: this.create_client.address,
                remark: this.create_client.remark,
            }

            await SystemService.create_all('Client', { data: [ client ]})
                .then(async res => {
                    const client_id = res.data?.id
                    if (client_id) {
                        await this.create_client_branch(client_id)
                        this.$emit('onCreated', true)
                        Swal.fire({
                            icon: 'success',
                            title: 'เพิ่มข้อมูลบริษัทสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.close_modal()
                        this.clear_inputs()
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                
        },
        close_modal() {
            document.getElementsByClassName('ant-modal-close-x')[0].click()
        },
        clear_inputs() {
            this.create_client.client_no = null
            this.create_client.tour_type_id = null
            this.create_client.car_company_id = null
            this.create_client.fullname = null
            this.create_client.tax_id = null
            this.create_client.email = null
            this.create_client.phone_no = null
            this.create_client.phone_no_backup = null
            this.create_client.fax_no = null
            this.create_client.address = null
            this.create_client.remark = null
            this.branches = []
        },
        afterClose() {
            this.$emit('afterClose')
        },
        async init_options() {
            this.car_company_list = await SystemService.get_all('CarCompany')
        }
    },
    mounted() {
        this.init_options()
    }
}
</script>

<style scoped>
.ant-col-12 {
    min-width: 300px;
    padding: 4px
}
.ant-select, .ant-picker {
    width: 100%
}
.ant-col {
    width: 100%;
    margin: 8px
}
.ant-form-item {
    margin-bottom: 0;
}
.quotation-container {
    padding: 15px;
    height: 100vh;
}</style>