import { layouts } from "chart.js";

let appearance_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_6: {
            input_type: 'divider',
            label: 'รูปพรรณ',
            col_span: 23
        },
        photo_1: {
            input_type: 'photo',
            label: 'ด้านหน้า',
            col_span: 10,
            layout: 'vertical'
        },
        photo_2: {
            input_type: 'photo',
            label: 'ด้านหลัง',
            col_span: 10,
            layout: 'vertical'
        },
        photo_3: {
            input_type: 'photo',
            label: 'ด้านซ้าย',
            col_span: 10,
        },
        photo_4: {
            input_type: 'photo',
            label: 'ด้านขวา',
            col_span: 10,
            layout: 'vertical'
        },
        photo_5: {
            input_type: 'photo',
            label: 'ชั้นบน',
            col_span: 10,
            layout: 'vertical'
        },
        photo_6: {
            input_type: 'photo',
            label: 'ชั้นล่าง',
            col_span: 10,
            layout: 'vertical'
        },
        photo_7: {
            input_type: 'photo',
            label: 'แผ่นเพลทตัวรถ',
            col_span: 10,
            layout: 'vertical'
        },
        photo_8: {
            input_type: 'photo',
            label: 'แผ่นเพลทหมายเลขตัวถัง',
            col_span: 10,
            layout: 'vertical'
        },
    }
};

export default appearance_fields