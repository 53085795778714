import { layouts } from "chart.js";

let document_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_4: {
            input_type: 'divider',
            label: 'เอกสารสำคัญต่างๆ',
            col_span: 23
        },
        file_asset: {
            input_type: 'table_files',
            placeholder: '',
            col_span: 23
        },
    }
};

export default document_fields