import { layouts } from "chart.js";

let car_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_1: {
            input_type: 'divider',
            label: 'ข้อมูลรถ',
            col_span: 23
        },
        car_company_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarCompany',
            label_key: 'fullname',
            placeholder: 'บริษัทรถ',
            // required: true,
            required: false,
            col_span: 23
        },
        car_type_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarType',
            label_key: 'name',
            placeholder: 'ชนิดรถ',
            // required: true,
            required: true,
            col_span: 11
        },
        car_group_id: {
            input_type: 'sub_select',
            placeholder: 'ประเภทที่นั่งรถ',
            modelname: 'CarGroup',
            label_key: 'name',
            parent: {
                list: 'CarType',
                sub_list_name: 'car_groups',
                key: 'car_type_id'
            },
            // required: true,
            col_span: 11,
        },
        car_no: {
            input_type: 'text',
            placeholder: 'เบอร์รถ',
            // required: true,
            required: true,
            col_span: 11
        },
        plate_no: {
            input_type: 'text',
            placeholder: 'เลขทะเบียนรถ',
            // required: true,
            required: true,
            col_span: 11
        },
        driver_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Driver',
            label_key: 'firstname',
            label_function: (driver) => {
                return `${driver.firstname} ${driver.lastname}`},
            placeholder: 'คนขับรถ',
            search_select: true,
            // required: true,
            required: false,
            col_span: 11
        },
        car_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_year: {
            input_type: 'text',
            placeholder: 'ปีรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_model: {
            input_type: 'text',
            placeholder: 'รุ่นรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        parking_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Parking',
            label_key: 'name',
            placeholder: 'ที่จอดรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_source_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarSource',
            label_key: 'name',
            placeholder: 'ที่มารถ',
            required: false,
            col_span: 11
        },
        remark: {
            input_type: 'textarea',
            placeholder: 'รายละเอียดรถเพิ่มเติม',
            required: false,
            col_span: 23
        },
    }
};

export default car_fields