<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type == 'all'" class="app-content">
            <a-card title="การแจ้งซ่อม">
                <template v-if="!sjinda" #extra>
                    <a-space>
                        <router-link v-if="editable" :to="`/app/repair/add`">
                            <a-button style="display: flex" type="primary" size="large">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                เพิ่มการแจ้งซ่อม
                            </a-button>
                        </router-link>
                    </a-space>
                </template>
                <a-row v-if="sjinda" style="display: flex; justify-content: flex-end;">
                    <a-space>
                        <router-link v-if="editable" :to="`/app/repair/add`">
                            <a-button style="display: flex" type="primary" size="large">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                เพิ่มการแจ้งซ่อม
                            </a-button>
                        </router-link>
                    </a-space>
                </a-row>
                <br>
                <a-row type="flex">
                    <a-col flex="1 0" style="margin-right:1rem">
                        <a-input-search v-model:value="search_car" :placeholder="`ค้นหา ทะเบียนรถ`" enter-button="ค้นหา"/>
                    </a-col>
                    <a-col flex="1 0" style="margin-right:1rem">
                        <a-select v-if="select_company" style="width: 100%" v-model:value="selected_company"
                            placeholder="เลือกลูกค้า/บริษัท" :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option v-for="{ fullname, id }, i in car_company_list" :key="i" :value="id">
                                {{ fullname }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col flex="1 0" style="margin-right:1rem">
                        <a-select v-if="select_status" style="width: 100%" v-model:value="selected_status"
                            placeholder="เลือกสถานะ" :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option v-for="{ id, status } in repair_status_list" :key="id" :value="id">{{
                                    status
                            }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col flex="1 0" style="margin-right:1rem">
                        <a-select v-if="select_type_id" style="width: 100%" v-model:value="selected_type_id"
                            placeholder="เลือกประเภท" :dropdownMatchSelectWidth="false">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>
                            <a-select-option v-for="{ id, name } in car_repair_type_list" :key="id" :value="id">{{
                                    name
                            }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-button @click="clear_search()" v-if="clear" type="dashed">CLEAR</a-button>
                </a-row>
                <br>
                <a-row>
                    <a-table :columns="[
                        {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            sorter: true,
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'car',
                            key: 'car',
                            sorter: true,
                            width: 125,
                            align: 'center',
                        }, {
                            title: 'เบอร์รถ',
                            dataIndex: 'car_no',
                            key: 'car_no',
                            sorter: true,
                            width: 125,
                            align: 'center',
                        }, {
                            title: 'ประเภทการซ่อม',
                            dataIndex: 'car_repair_type_id',
                            key: 'car_repair_type_id',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'อาการ',
                            dataIndex: 'story',
                            key: 'story',
                            width: 125,
                            ellipsis: true,
                            align: 'center',
                        }, {
                            title: 'วันที่แจ้งซ่อม',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            width: 120,
                            align: 'center',
                        }, {
                            title: 'วันที่เริ่มซ่อม',
                            dataIndex: 'time_start',
                            key: 'time_start',
                            width: 120,
                            align: 'center',
                        }, {
                            title: 'วันที่ซ่อมเสร็จ',
                            dataIndex: 'time_end',
                            key: 'time_end',
                            width: 120,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 120,
                            align: 'center',
                            fixed: 'right'
                        }
                    ].filter((c)=> !editable ? !c.key.includes('id') : true)" :data-source="filtered_list(repairing_list)" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <a-row>
                                    <a @click="edit_repair_status(record.id, record)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">menu</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <router-link :to="`/app/repair/${record.id}`">
                                        <span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span>
                                    </router-link><a-divider type="vertical"></a-divider>
                                    <a @click="remove_car_repair(record.id, record.quotationNo)"><span
                                            style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </a-row>
                            </template>
                            <template v-if="column.key === 'car_repair_type_id'">
                                {{ record.car_repair_type?.name }}
                            </template>
                            <template v-else-if="column.key == 'isAir'">
                                <a-switch v-model:checked="record.isAir" />
                            </template>
                            <template v-else-if="column.key === 'created_at'">
                                {{ render_date(record.created_at) }}
                            </template>
                            <template v-else-if="column.key === 'time_start'">
                                {{ render_date(record.time_start) }}
                            </template>
                            <template v-else-if="column.key === 'time_end'">
                                {{ render_date(record.time_end) }}
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>

        <div v-else-if="render_type == 'add'" class="app-content">
            <a-card title="รายการแจ้งซ่อม">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>

                <a-divider orientation="left">ข้อมูลที่ต้องการซ่อม</a-divider>

                <a-row style="margin-top: 35px;" type="flex">
                    <a-col :span="8">
                        <a-form-item label="ลูกค้า/บริษัท" name="car_company"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-select style="width: 95%" v-model:value="car_repair.car_company_id"
                            placeholder="เลือกบริษัท">
                            <a-select-option v-for="{ id, fullname }, i in car_company_list" :key="i" :value="id">{{
                                    fullname
                            }}</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="รถที่ต้องการซ่อม" name="car_id"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-select style="width: 95%" v-model:value="car_repair.car_id" placeholder="เลือกรถ"
                            @change="select_car(car_repair.car_id)">
                            <a-select-option v-for="{ id, plate_no }, i in car_list" :key="i" :value="id">{{
                                    plate_no
                            }}</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="ประเภทรถที่ต้องการซ่อม" name="car_repair_type_id"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-select style="width: 95%" v-model:value="car_repair.car_repair_type_id" placeholder="เลือกประเภท" >
                            <a-select-option v-for="{ id, name }, i in car_repair_type_list" :key="i" :value="id">{{
                                    name
                            }}</a-select-option>
                        </a-select>
                    </a-col>
                </a-row>

                <a-row style="margin-top: 35px;" type="flex">
                    <a-col :span="8">
                        <a-form-item label="วันที่แจ้งซ่อม" name="created_at"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-date-picker placeholder="ระบุวันที่แจ้งซ่อม" style="width: 95%"
                            v-model:value="car_repair.created_at" />
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="วันที่เริ่มต้น" name="time_start">
                        </a-form-item>
                        <a-date-picker placeholder="ระบุวันที่เริ่มต้น" style="width: 95%"
                            v-model:value="car_repair.time_start" />
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="วันที่เสร็จสิ้นการซ่อม" name="time_end">
                        </a-form-item>
                        <a-date-picker placeholder="ระบุวันที่เสร็จสิ้นการซ่อม" style="width: 95%"
                            v-model:value="car_repair.time_end" />
                    </a-col>
                </a-row>

                <a-row style="margin-top: 35px;" type="flex">
                    <a-col :span="6">
                        <a-form-item label="ทะเบียนรถ" name="plate_no">
                        </a-form-item>
                        <a-input disabled placeholder="ระบุทะเบียนรถ" style="width: 95%"
                            v-model:value="selected_car.plate_no" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="ประเภทรถ" name="time_start">
                        </a-form-item>
                        <a-input disabled placeholder="ระบุประเภทรถ" style="width: 95%"
                            v-model:value="selected_car.car_type_name" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="ชื่อพนักงานคนขับ" name="time_end">
                        </a-form-item>
                        <a-input disabled placeholder="ไม่มีคนขับ" style="width: 95%"
                            v-model:value="selected_car.driver_name" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="เบอร์โทรคนขับ" name="time_end">
                        </a-form-item>
                        <a-input disabled placeholder="ไม่มีคนขับ" style="width: 95%"
                            v-model:value="selected_car.driver_tel" />
                    </a-col>
                </a-row>

                <a-row style="margin-top: 35px;" type="flex">
                    <a-col :span="6">
                        <a-form-item label="เลขไมล์ปัจจุบัน" name="plate_no">
                        </a-form-item>
                        <a-input disabled placeholder="เลขไมล์รวม (กิโลเมตร)" style="width: 95%"
                            v-model:value="distance" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="วันที่บันทึกเลขไมล์" name="time_start">
                        </a-form-item>
                        <a-input disabled placeholder="วันที่บันทึก" style="width: 95%"
                            v-model:value="updatedAtOdometer" />
                    </a-col>
                </a-row>

                <a-divider style="margin-top: 35px;" orientation="left">รายการแจ้งซ่อม</a-divider>
                <a-table :columns="[
                    {
                        title: 'รายการแจ้งซ่อม',
                        dataIndex: 'story',
                        key: 'story',
                    }, 
                    {
                        title: 'ประเภทรายการ',
                        dataIndex: 'repair_story_type_id',
                        key: 'repair_story_type_id',
                    }, 
                    {
                        title: 'รายละเอียด',
                        dataIndex: 'detail',
                        key: 'detail',
                    }, 
                    {
                        title: 'ค่าใช้จ่าย',
                        dataIndex: 'repair_expense',
                        key: 'repair_expense',
                    }, 
                    {
                        title: 'ตัวเลือก',
                        dataIndex: 'id',
                        key: 'id',
                        width: 100,
                        align: 'center',
                        fixed: 'right'
                    }
                ]" :data-source="car_repair.repair_story" bordered size="small">
                    <template #bodyCell="{ column, record, index}">
                        <template v-if="column.key === 'id'">
                            <a-row>
                                <a @click="edit_car_repair_story(index, record)"><span style="font-size: 14px"
                                        class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                <a-divider type="vertical"></a-divider>
                                <a @click="remove_car_repair_story(index, record)"><span
                                        style="font-size: 14px" class="material-symbols-outlined">delete</span></a>
                            </a-row>
                        </template>
                        <template v-else-if="column.key === 'repair_story_type_id'">
                            {{ getRepairStoryTypeName(record.repair_story_type_id) }}
                        </template> 
                    </template>

                </a-table>
                <a-row justify="end">
                    <a-col>
                        <a-button @click="create_car_repair_story()" type="primary" html-type="submit" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            เพิ่มรายการแจ้งซ่อม
                        </a-button>
                    </a-col>
                </a-row>

                <a-drawer v-model:visible="show_drawer" class="custom-class" style="color: red" title="รายการแจ้งซ่อม"
                    placement="right">
                    <a-row type="flex">
                        <a-col :span="24">
                            <a-form-item label="อาการ" name="time_start">
                            </a-form-item>
                            <a-input placeholder="ระบุอาการ" style="width: 95%"
                                v-model:value="repair_story.story" />
                        </a-col>
                        <a-col style="margin-top: 30px;" :span="24">
                            <a-form-item label="ประเภทรายการ">
                            </a-form-item>
                            <a-select placeholder="เลือกประเภท" style="width: 95%" v-model:value="repair_story.repair_story_type_id">
                                <a-select-option v-for="item in repairStoryTypesList" :key="item.id" :value="item.id">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col style="margin-top: 30px;" :span="24">
                            <a-form-item label="รายละเอียด" name="time_end">
                            </a-form-item>
                            <a-textarea placeholder="ระบุรายละเอียด" style="width: 95%"
                                v-model:value="repair_story.detail" />
                        </a-col>
                        <a-col style="margin-top: 30px;" :span="24">
                            <a-form-item label="ค่าใช้จ่าย">
                            </a-form-item>
                            <a-input placeholder="ระบุจำนวนเงิน (บาท)" style="width: 95%"
                                v-model:value="repair_story.repair_expense" />
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-item label="รูปภาพส่วนที่เสียหาย" name="time_end">
                            </a-form-item>
                            <a-upload v-model:file-list="fileList" list-type="picture-card"
                                class="avatar-uploader"
                                :maxCount="5"
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                :action="upload_story"
                                method="GET"
                                @remove="remove_repair_story_photo"
                                >
                                <div v-if="fileList?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                                    <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                                    <div style="margin-top: 8px">Upload</div>
                                </div>
                            </a-upload>
                        </a-col>
                    </a-row>
                    <br><br>
                    <a-row justify="end">
                        <a-col>
                            <a-button @click="submit_repair_story()" type="primary" html-type="submit" size="large"
                                style="margin: 4px;text-align: right">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Add</span>
                                เพิ่มข้อมูล
                            </a-button>
                        </a-col>
                    </a-row>
                </a-drawer>

                <a-divider orientation="left">เอกสารที่เกี่ยวข้อง</a-divider>
                <a-upload v-model:file-list="car_repair.photos" list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        :action="upload_files"
                        method="GET">
                        <div v-if="car_repair.photos.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                            <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                </a-upload>

                <a-divider orientation="left">หมายเหตุ (ผู้แจ้งซ่อม)</a-divider>
                <a-textarea placeholder="ระบุหมายเหตุ" v-model:value="car_repair.remark" />

                <a-row justify="end">
                    <a-col>
                        <a-button @click="submit_create_car_repair()" type="primary" html-type="submit" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            บันทึกข้อมูล
                        </a-button>
                    </a-col>
                </a-row>
            </a-card>
        </div>

        <div v-else class="app-content">
            <a-card title="รายการแจ้งซ่อม">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>

                <a-divider orientation="left">ข้อมูลที่ต้องการซ่อม</a-divider>

                <a-row type="flex">
                    <a-col :span="8">
                        <a-form-item label="ลูกค้า/บริษัท" name="car_company"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-select style="width: 95%" v-model:value="car_repair.car_company_id"
                            placeholder="เลือกบริษัท">
                            <a-select-option v-for="{ id, fullname }, i in car_company_list" :key="i" :value="id">{{
                                    fullname
                            }}</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="รถที่ต้องการซ่อม" name="plate_no"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-select style="width: 95%" v-model:value="car_repair.car_id" placeholder="เลือกรถ"
                            @change="select_car(car_repair.car_id)">
                            <a-select-option v-for="{ id, plate_no }, i in car_list" :key="i" :value="id">{{
                                    plate_no
                            }}</a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="รถที่ต้องการซ่อม" name="plate_no"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-select style="width: 95%" v-model:value="car_repair.car_repair_type_id" placeholder="เลือกประเภท" >
                            <a-select-option v-for="{ id, name }, i in car_repair_type_list" :key="i" :value="id">{{
                                    name
                            }}</a-select-option>
                        </a-select>
                    </a-col>
                </a-row>

                <a-row type="flex">
                    <a-col :span="8">
                        <a-form-item label="วันที่แจ้งซ่อม" name="created_at"
                            :rules="[{ required: true, message: 'Please input field!' }]">
                        </a-form-item>
                        <a-date-picker placeholder="ระบุวันที่แจ้งซ่อม" style="width: 95%"
                            v-model:value="car_repair.created_at" />
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="วันที่เริ่มต้น" name="time_start">
                        </a-form-item>
                        <a-date-picker placeholder="ระบุวันที่เริ่มต้น" style="width: 95%"
                            v-model:value="car_repair.time_start" />
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="วันที่เสร็จสิ้นการซ่อม" name="time_end">
                        </a-form-item>
                        <a-date-picker placeholder="ระบุวันที่เสร็จสิ้นการซ่อม" style="width: 95%"
                            v-model:value="car_repair.time_end" />
                    </a-col>
                </a-row>

                <a-row type="flex">
                    <a-col :span="6">
                        <a-form-item label="ทะเบียนรถ" name="plate_no">
                        </a-form-item>
                        <a-input disabled placeholder="ระบุทะเบียนรถ" style="width: 95%"
                            v-model:value="selected_car.plate_no" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="ประเภทรถ" name="time_start">
                        </a-form-item>
                        <a-input disabled placeholder="ระบุประเภทรถ" style="width: 95%"
                            v-model:value="selected_car.car_type_name" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="ชื่อพนักงานคนขับ" name="time_end">
                        </a-form-item>
                        <a-input disabled placeholder="ไม่มีคนขับ" style="width: 95%"
                            v-model:value="selected_car.driver_name" />
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="เบอร์โทรคนขับ" name="time_end">
                        </a-form-item>
                        <a-input disabled placeholder="ไม่มีคนขับ" style="width: 95%"
                            v-model:value="selected_car.driver_tel" />
                    </a-col>
                </a-row>

                <a-divider orientation="left">รายการแจ้งซ่อม</a-divider>
                <a-table :columns="[
                    {
                        title: 'รายการแจ้งซ่อม',
                        dataIndex: 'story',
                        key: 'story',
                    }, {
                        title: 'รายละเอียด',
                        dataIndex: 'detail',
                        key: 'detail',
                    }, {
                        title: 'ตัวเลือก',
                        dataIndex: 'id',
                        key: 'id',
                        width: 100,
                        align: 'center',
                        fixed: 'right'
                    }
                ]" :data-source="car_repair.repair_story" bordered size="small"
                    :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                    <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'id'">
                                <a @click="edit_car_repair_story(index, record)"><span style="font-size: 14px"
                                        class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                <a-divider type="vertical"></a-divider>
                                <a @click="remove_car_repair_story(index, record)"><span
                                        style="font-size: 14px" class="material-symbols-outlined">delete</span></a>
                        </template>
                    </template>

                </a-table>
                <a-row justify="end">
                    <a-col>
                        <a-button @click="create_car_repair_story()" type="primary" html-type="submit" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            เพิ่มรายการแจ้งซ่อม
                        </a-button>
                    </a-col>
                </a-row>

                <a-drawer v-model:visible="show_drawer" class="custom-class" style="color: red" title="รายการแจ้งซ่อม"
                    placement="right">
                    <a-row type="flex">
                        <a-col :span="24">
                            <a-form-item label="อาการ" name="time_start">
                            </a-form-item>
                            <a-input placeholder="ระบุอาการ" style="width: 95%"
                                v-model:value="repair_story.story" />
                        </a-col>
                        <a-col :span="24">
                            <a-form-item label="รายละเอียด" name="time_end">
                            </a-form-item>
                            <a-textarea placeholder="ระบุรายละเอียด" style="width: 95%"
                                v-model:value="repair_story.detail" />
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-item label="รูปภาพส่วนที่เสียหาย" name="time_end">
                            </a-form-item>
                            <a-upload v-model:file-list="fileList" list-type="picture-card"
                                class="avatar-uploader"
                                :maxCount="5"
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                :action="upload_story"
                                method="GET"
                                @remove="remove_repair_story_photo"
                                >
                                <div v-if="fileList?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                                    <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                                    <div style="margin-top: 8px">Upload</div>
                                </div>
                            </a-upload>
                        </a-col>
                    </a-row>
                    <br><br>
                    <a-row justify="end">
                        <a-col>
                            <a-button @click="submit_repair_story()" type="primary" html-type="submit" size="large"
                                style="margin: 4px;text-align: right">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Add</span>
                                เพิ่มข้อมูล
                            </a-button>
                        </a-col>
                    </a-row>
                </a-drawer>

                <a-divider orientation="left">เอกสารที่เกี่ยวข้อง</a-divider>
                <a-upload v-model:file-list="car_repair.photos" list-type="picture-card"
                        class="avatar-uploader"
                        :maxCount="5"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        :action="upload_files"
                        method="GET">
                        <div v-if="car_repair.photos?.length < 5" style="height: 104px; width: 104px; padding: 2rem">
                            <span style="font-size: 20px" class="material-symbols-outlined">add</span>
                            <div style="margin-top: 8px">Upload</div>
                        </div>
                </a-upload>

                <a-divider orientation="left">หมายเหตุ (ผู้แจ้งซ่อม)</a-divider>
                <a-textarea placeholder="ระบุหมายเหตุ" v-model:value="car_repair.remark" />

                <a-row justify="end">
                    <a-col>
                        <a-button @click="submit_update_car_repair()" type="primary" html-type="submit" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            บันทึกข้อมูล
                        </a-button>
                    </a-col>
                </a-row>
            </a-card>
        </div>

        <a-modal v-model:visible="show_modal" title="เลือกสถานะ" ok-text="บันทึก" cancel-text="ยกเลิก" @ok="save_status">
            <a-row type="flex" style="flex-direction: column">
                <a-form-item label="สถานะ" />
                <a-col flex="1 0">
                    <a-select style="width: 100%" v-model:value="selected_repair_status" placeholder="เลือกสถานะ"
                        :dropdownMatchSelectWidth="false">
                        <a-select-option v-for="{ id, status } in repair_status_list" :key="id" :value="id">{{
                                status
                        }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>

<script>
import SystemService from '../../api/SystemService'
import CarCompanyService from '../../api/CarCompanyService'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)
import Utility from '../../utility'
import CompanyService from '../../api/CompanyService'
export default {
    name: 'repair',
    computed: {
        updatedAtOdometer() {
            if (this.selected_car && this.selected_car.odos) {
                const odo = this.selected_car.odos.find(odo => odo.is_start === 1);
                return odo ? dayjs(odo.updated_at).format('YYYY-MM-DD') : null;
            }
            return null;
        },
        distance() {
            if (this.selected_car && this.selected_car.odos) {
                const odo = this.selected_car.odos.find(odo => odo.is_start === 1);
                return odo ? odo.distance : null;
            }
            return null;
        },
        render_type() {
            this.clear_selected_car()
            if(this.$route.params.type != 'add' && this.$route.params.type != 'all'){
                this.init_edit_repair()
            }
            return this.$route.params.type
        }
    },
    data() {
        return {
            repairStoryTypesList: [],
            editable: Utility.get_permission_editable_with_key('manage_repair'),
            selected_company: null,
            businessType: null,
            sjinda: false,
            selected_status: null,
            selected_type_id: null,
            search_car: null,
            repairing_list: [],
            car_company_list: [],
            car_list: [],
            repair_status_list: [],
            car_repair_type_list: [],
            select_company: true,
            select_status: true,
            select_type_id: true,
            clear: true,

            /* Switch */
            show_modal: false,
            show_drawer: false,
            car_repair_photos: [],
            repair_story_photos: [],

            /* Update */
            selected_repair_id: null,
            selected_repair_status: null,

            /* Create */
            fileList: [],
            car_repair: {
                photos: [],
                repair_story: []
            },
            select_car_company: null,
            selected_car: {
                plate_no: null,
                car_type_name: null,
                car_type_id: null,
                driver_name: null,
                driver_tel: null
            },
            repair_story: {},
            is_update_modal: false,
            update_story_index: null,
        }
    },
    methods: {
        getRepairStoryTypeName(id) {
            const target = this.repairStoryTypesList.find(({ id: repair_story_type_id }) => repair_story_type_id === id);
            return target ? `${target.name}` : "";
        },
        async init_edit_repair() {
            var repair = await CarCompanyService.get_car_repair_detail(this.$route.params.type)
            const { photo_1, photo_2, photo_3, photo_4, photo_5 } = repair
            const photos = [photo_1, photo_2, photo_3, photo_4, photo_5].filter(e => e != null).map((photo, i) => {
                return {
                    uid: i,
                    name: photo+1,
                    status: 'done',
                    url: photo
                }
            })
            this.car_repair = repair
            this.car_repair.photos = photos
            this.car_repair.created_at = dayjs(repair.created_at)
            this.car_repair.time_start = dayjs(repair.time_start)
            this.car_repair.time_end = dayjs(repair.time_end)
            this.select_car(this.car_repair.car_id)
        },
        render_date(datetime) {
            return dayjs(datetime).format('DD/MM/YYYY HH:mm')
        },
        remove_repair_story_photo(e) {
            const { url } = e
            this.fileList = this.fileList.filter(({ status }) => status != 'removed')
            this.repair_story_photos.splice(this.repair_story_photos.indexOf(url), 1)
        },
        submit_repair_story() {
            let j = 0;
            var photo_url_list = this.fileList.filter(f=> f.url)
            for (var i = 0; i < photo_url_list.length; i++) {
                const target = photo_url_list[i]
                j = i+1;
                this.repair_story['photo_' + (j)] = target.url
            }

            let k = 0;
            for (var i = 0; i < this.repair_story_photos.length; i++) {
                const target = this.repair_story_photos[i]
                k = k+1;
                this.repair_story['photo_' + (k)] = target
            }


            if(this.is_update_modal){
                this.car_repair.repair_story[this.update_story_index] = this.repair_story
            }else{
                this.car_repair.repair_story.push(this.repair_story)
            }
            this.repair_story = {}
            this.update_story_index = null
            this.show_drawer = false
        },
        open_drawer() {
            this.repair_story = {}
            this.fileList = []
            this.show_drawer = true
        },
        getBase64(file, key) {
            const vue = this
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                vue[key].push(reader.result)
            };
        },
        async upload_files(e) {
            this.getBase64(e, 'car_repair_photos')
            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        async upload_story(e) {
            this.getBase64(e, 'repair_story_photos')
            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        async select_car(car_id) {
            await SystemService.get_id('Car', car_id)
                .then(res => {
                    this.selected_car = res
                    this.selected_car.car_type_name = this.selected_car.car_type?.name
                    this.selected_car.car_type_id = this.selected_car.car_type?.id
                    const { driver } = this.selected_car
                    this.selected_car.driver_name = driver?.firstname
                    this.selected_car.driver_tel = driver?.phone_no
                })
        },
        async submit_create_car_repair() {
            for (var i = 0; i < this.car_repair_photos.length; i++) {
                const target = this.car_repair_photos[i]
                this.car_repair['photo_' + (i + 1)] = target
            }
            this.car_repair.car_type_id =  this.selected_car.car_type_id
            Swal.fire({
                title: 'กรุณารอสักครู่',
                text: 'กำลังบันทึกข้อมูล',
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading()
                    await CarCompanyService.create_car_repair(this.car_repair)
                        .then(res => {
                            if (res) {
                                Swal.close()
                                this.car_repair = {
                                    car_repair_photos: []
                                }
                                this.car_repair_photos = []
                                this.clear_selected_car()
                                this.init_repair()
                                this.$router.go(-1)
                            }
                        })
                },
                willClose: () => {
                    Swal.fire({
                        title: 'สร้างรายการสำเร็จ',
                        icon: 'success',
                        timer: 1000,
                        timerProgressBar: false,
                        showConfirmButton: false,
                    })
                }
            })
        },
        async submit_update_car_repair() {
            const { id } = this.car_repair
            this.car_repair.photo_1 = null
            this.car_repair.photo_2 = null
            this.car_repair.photo_3 = null
            this.car_repair.photo_4 = null
            this.car_repair.photo_5 = null

            let j = 0;
            var photo_url_list = this.car_repair.photos.filter(f=> f.url)

            for (var i = 0; i < photo_url_list.length; i++) {
                const target = photo_url_list[i]
                j = i+1;
                this.car_repair['photo_' + (j)] = target.url
            }
            for (var i = 0; i < this.car_repair_photos.length; i++) {
                const target = this.car_repair_photos[i]
                j = j+1;
                this.car_repair['photo_' + (j)] = target
            }

            Swal.fire({
                title: 'กรุณารอสักครู่',
                text: 'กำลังบันทึกข้อมูล',
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading()
                    await CarCompanyService.edit_car_repair(id, this.car_repair)
                        .then(res => {
                            if (res) {
                                Swal.close()
                                this.init_repair()
                            }
                        })
                },
                willClose: () => {
                    Swal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        icon: 'success',
                        timer: 1000,
                        timerProgressBar: false,
                        showConfirmButton: false,
                    })
                }
            })
            this.$router.go(-1)
            this.init_repair()
        },
        remove_car_repair(id) {
            Swal.fire({
                title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบรายการนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    CarCompanyService.remove_car_repair(id).then(res => {
                        this.init_repair()
                        Swal.fire(
                            'ลบรายการสำเร็จ!',
                            'รายการของคุณถูกลบแล้ว',
                            'success'
                        )
                    })
                }
            })
        },
        edit_repair_status(id, repair) {
            const { car_repair_status_id } = repair
            this.show_modal = true
            this.selected_repair_id = id
            this.selected_repair_status = car_repair_status_id
        },
        create_car_repair_story(){
            this.is_update_modal = false
            this.open_drawer()
        },
        edit_car_repair_story(index, data){
            this.is_update_modal = true
            this.car_repair_photos = []
            this.open_drawer()
            const { id, story, detail, photo_1, photo_2, photo_3, photo_4, photo_5 } = data
            const photos = [photo_1, photo_2, photo_3, photo_4, photo_5].filter(e => e != null).map((photo, i) => {
               return photo.uid ? photo : {
                        uid: i,
                        name: photo+1,
                        status: 'done',
                        url: photo
                }
            })

            this.fileList = photos
            this.repair_story.id = id
            this.repair_story.story = story
            this.repair_story.detail = detail
            this.is_update_modal = true
            this.update_story_index = index
        },
        remove_car_repair_story(index, data){
            if(data.id){
                Swal.fire({
                title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบรายการนี้!',
                cancelButtonText: 'ยกเลิก'
                }).then((result) => {
                    if (result.isConfirmed) {
                        SystemService.delete_all('RepairStory',data.id).then(res => {
                            this.car_repair.repair_story.splice(index, 1)
                            this.init_repair()
                            Swal.fire(
                                'ลบรายการสำเร็จ!',
                                'รายการของคุณถูกลบแล้ว',
                                'success'
                            )
                        })
                    }
                })
            }else{
                this.car_repair.repair_story.splice(index, 1)
            }
        },
        async save_status() {
            await CarCompanyService.edit_repair_status(this.selected_repair_id, { car_repair_status_id: this.selected_repair_status })
            this.selected_repair_status = null
            this.show_modal = false
            this.init_repair()
            Swal.fire(
                'แก้ไขสถานะสำเร็จ!',
                'แก้ไขสถานะรายการแจ้งซ่อมสำเร็จแล้ว',
                'success'
            )
        },
        clear_search() {
            this.selected_company = null
            this.selected_status = null
            this.search_car = null
            this.selected_type_id = null
        },
        clear_selected_car() {
            this.selected_car = {}
            this.car_repair = {
                photos: [],
                repair_story: []
            }
            this.car_repair_photos = []
        },
        filtered_list(array) {
            return array.filter(item => {
                if (this.selected_company && this.selected_status) {
                    return item.car_company_id == this.selected_company && item.car_repair_status_id == this.selected_status
                } else if (this.selected_company) {
                    return item.car_company_id == this.selected_company
                } else if (this.selected_status) {
                    return item.car_repair_status_id == this.selected_status
                } else {
                    return true
                }
            })
            .filter(({ car_repair_type_id }) => {
                if (this.selected_type_id) {
                    return car_repair_type_id == this.selected_type_id
                } else {
                    return true
                }
            })
            .filter(({ car }) => {
                if (this.search_car) {
                    return car.includes(this.search_car)
                } else {
                    return true
                }
            }).sort((a,b) => b.id - a.id)
        },
        async init_businessType() {
            this.businessType = await CompanyService.getBusinessId(this.car_company_list[0].company_id)
            if (this.car_company_list[0].company_id === 39 || this.businessType.data === 2){
                this.sjinda = true
            }
        },
        async init_repair() {
            this.car_company_list = await SystemService.get_all('CarCompany')
            this.init_businessType()
            this.repairing_list = await CarCompanyService.get_car_repair()
            this.repair_status_list = await SystemService.get_all('CarRepairStatus')
            this.car_list = await SystemService.get_all('Car')
            this.car_repair_type_list = await SystemService.get_all('CarRepairType')
            this.repairStoryTypesList = await SystemService.get_core('getRepairStoryTypes')
        }
    },
    mounted() {
        this.init_repair()
    }
}
</script>

<style scoped>
.ant-form-item {
    margin: 0
}
.ant-table-thead .center-header {
  text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
}
</style>