<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type != 'edit_user_admin'" class="app-content">
            <a-spin :spinning="loading">
                <a-card :title="title">
                    <template #extra>
                        <a-space>
                            <router-link :to="`/app/admin/register`">
                                <a-button v-if="button_title" style="display: flex;"
                                    type="primary" size="large">
                                    <template #icon><span class="material-symbols-outlined">Add</span></template>
                                    {{ button_title }}
                                </a-button>
                            </router-link>
                        </a-space>
                    </template>

                    <a-row>
                        <a-space>
                            <a-input-search v-if="input" v-model:value="search_input"
                                :placeholder="`ค้นหา ${input}`" enter-button="ค้นหา" style="width: 260px;"/>
                        </a-space>

                            <a-select
                                v-model:value="search_business_type"
                                label-in-value
                                style="width: 135px; margin-left: 1rem"
                                placeholder="ประเภทธุรกิจ"
                            >
                                <a-select-option :value="1">Car Rental</a-select-option>
                                <a-select-option :value="2">Logistics</a-select-option>
                            </a-select>

                        <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>

                    </a-row>

                    <br>
                    <a-row>
                        <a-table :columns="columns" :data-source="filtered_list(show_table_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'rights'">
                                    <div> ADMIN </div>
                                </template>
                                <template v-if="column.key === 'fullname'">
                                    <div> {{record.admin_fullname}} </div>
                                </template>
                                <template v-if="column.key === 'business_type_id'">
                                    <div> {{record.business_type_name}} </div>
                                </template>
                                <template v-if="column.key === 'phone_no'">
                                    <div> {{record.admin_phone_no}} </div>
                                </template>
                                <template v-if="column.key === 'company_type_id'">
                                    <div> {{record.company_type_name}} </div>
                                </template>
                                <template v-if="column.key === 'email'">
                                    <div> {{record.admin_email}} </div>
                                </template>
                                <template v-if="column.key == 'use_user'" >
                                    <a-row style="display: flex; justify-content: center;">
                                        <a-button 
                                            :style="buttonCancelStyle(record.use_user)"
                                            @click="closeUseUser(record.admin_id)">
                                            <strong>OFF</strong>
                                        </a-button>
                                        <a-button 
                                            :style="buttonOpenStyle(record.use_user)"
                                            @click="openUseUser(record.admin_id)">
                                            <strong style="display: flex; justify-content: center;">ON</strong>
                                        </a-button>
                                    </a-row>
                                </template>
                                <template v-if="column.key === 'admin_id'">
                                    <div>
                                        <a @click="viewUser(record.company_id)"><span style="font-size: 18px" class="material-symbols-outlined">Visibility</span></a>
                                        <a-divider type="vertical"></a-divider>
                                        <a @click="edit_user(record.admin_id)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                        <a-divider type="vertical"></a-divider>
                                        <a @click="remove_user(record.admin_id, record)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                    </div>
                                </template>
                            </template>

                        </a-table>
                    </a-row>
                </a-card>
            </a-spin>
        </div>
        <a-spin :spinning="loading">
            <div v-if="render_type == 'edit_user_admin'" class="app-content">
            <a-card :title="`สิทธิการใช้งานเมนู`">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>
                <a-table 
                    :columns="permission_columns" 
                    :data-source="menu_permissions" 
                    bordered size="small"
                    class="permission-table"
                    :pagination="false">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key == 'enable'" >
                            <a-switch v-model:checked="record.enable" @change="onEnableSwitchChange(record.enable, record)"/>
                        </template>
                        <template v-if="column.key == 'editable'" >
                            <a-switch v-model:checked="record.editable" :disabled='!record.enable'/>
                        </template>
                    </template>
                </a-table>
                <a-row justify="end">
                    <a-col>
                        <a-button type="primary" html-type="submit" size="large"
                            style="margin: 4px;text-align: right"
                            @click="onPermissionSave">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Edit</span>
                                บันทึกข้อมูล
                        </a-button>
                    </a-col>
                </a-row>
            </a-card>
        </div>
        </a-spin>
    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import AdminService from '../../api/AdminService';
import UserService from '../../api/UserService';
export default {
    name: 'gas-station',
    computed: {
        render_type() {
            return this.$route.name
        },
        buttonOpenStyle() {
            return (useUser) => {
                let style = {
                    borderTopRightRadius: '50px',
                    borderBottomRightRadius: '50px',
                    fontSize: '10px',
                    height: '25px',
                    width: '35px'
                };
                if (useUser == 1) {
                    style.backgroundColor = '#1890FF';
                    style.color = 'white';
                }
                return style;
            };
        },
        buttonCancelStyle() {
            return (useUser) => {
                let style = {
                    borderTopLeftRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    fontSize: '10px',
                    height: '25px',
                    width: '35px'
                };
                if (useUser == 0) {
                    style.backgroundColor = '#9f9f9f';
                    style.color = 'white';
                }
                return style;
            };
        }
    },
    data() {
        return {
            loading: false,
            search_business_type: null,

            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            fileList: [],
            title: 'จัดการผู้ใช้ (Admin)',
            button_title: 'เพิ่มผู้ใช้งาน',
            input: 'Username / ชื่อบริษัท',
            search_input: null,
            columns: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    width: 200,
                    sorter: {
                        compare: (a, b) => a.username.localeCompare(b.username),
                        multiple: 1,
                    },
                }, 
                {
                    title: 'ชื่อบริษัท',
                    dataIndex: 'fullname',
                    key: 'fullname',
                    width: 250,
                    sorter: {
                        compare: (a, b) => {
                            if (a.fullname && b.fullname) {
                                return a.fullname.localeCompare(b.fullname);
                            }
                            return 0;
                        },
                        multiple: 2,
                    },
                }, 
                {
                    title: 'ประเภทธุรกิจ',
                    dataIndex: 'business_type_id',
                    key: 'business_type_id',
                    width: 120
                }, 
                {
                    title: 'ประเภทบริษัท',
                    dataIndex: 'company_type_id',
                    key: 'company_type_id',
                    width: 120
                }, 
                {
                    title: 'เบอร์โทรศัพท์',
                    dataIndex: 'phone_no',
                    key: 'phone_no',
                    width: 120
                }, 
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: 200
                }, 
                {
                    title: 'สิทธิ์',
                    dataIndex: 'rights',
                    key: 'rights',
                    width: 70
                }, 
                {
                    title: 'สถานะ',
                    dataIndex: 'use_user',
                    key: 'use_user',
                    width: 130,
                    align: 'center',
                }, 
                {
                    title: 'ตัวเลือก',
                    dataIndex: 'admin_id',
                    key: 'admin_id',
                    width: 130,
                    align: 'center',
                    fixed: 'right'
                }
            ],
            permission_columns: [
                {
                    title: 'เมนู',
                    dataIndex: 'menu',
                    key: 'menu',
                },
                   {
                    title: 'ใช้งาน',
                    dataIndex: 'enable',
                    key: 'enable',
                    width: 120,
                    fixed: 'right',
                    align:'center',
                },
                   {
                    title: 'แก้ไข',
                    dataIndex: 'editable',
                    key: 'editable',
                    width: 120,
                    fixed: 'right',
                    align:'center'
                },
            ],
            menu_permissions: [
				{
					key: "dashboard",
					menu: "แดชบอร์ด",
					enable: false,
					editable: false,
				},
				{
					key: "calendar",
					menu: "ตารางงาน",
					enable: false,
					editable: false,
				},
				{
					key: "schedule",
					menu: "ตารางการใช้รถ",
					enable: false,
					editable: false,
				},
				{
					key: "manage_tour",
					menu: "จัดการลูกค้าหรือบริษัท",
					enable: false,
					editable: false,
                    children: [
                    { key: "user_or_company", menu: "ลูกค้าหรือบริษัท", enable: false, editable: false},
                    { key: "coordinator", menu: "ผู้ประสานงาน", enable: false, editable: false },              
                    { key: "customer_group_type", menu: "ประเภทกลุ่มลูกค้า", enable: false, editable: false },
                    { key: "external_customer", menu: "ประวัติลูกค้าภายนอก", enable: false, editable: false },
                    { key: "guide", menu: "ไกด์", enable: false, editable: false },
                ],
				},
				{
					key: "quotation",
					menu: "ใบเสนอราคา",
					enable: false,
					editable: false,
                    children: [
                    { key: "quotation_sub", menu: "ใบเสนอราคา", enable: false, editable: false },
                    { key: "manage_notes", menu: "จัดการหมายเหตุ", enable: false, editable: false },
                ],
				},
				{
					key: "billing", 
					menu: "การวางบิล",
					enable: false,
					editable: false,
                    children: [
                    { key: "wait_bill", menu: "รอวางบิล", enable: false, editable: false },
                    { key: "overdue_bill", menu: "บิลค้างชำระ", enable: false, editable: false },
                    { key: "monthly_report", menu: "รายงานประจำเดือน", enable: false, editable: false },
                    { key: "guarantee_work", menu: "หลักค้ำประกันสัญญา", enable: false, editable: false },
                ],
				},
                {
					key: "booking",
					menu: "การจองจัดรถ",
					enable: false,
					editable: false,
				},
				{
					key: "company", 
					menu: "จัดการข้อมูลที่เกี่ยวกับยานพาหนะ",
					enable: false,
					editable: false,
                    children: [
                    { key: "manage_car_company", menu: "บริษัทรถ", enable: false, editable: false },
                    { key: "manage_car_type", menu: "ชนิดรถ", enable: false, editable: false },
                    { key: "car_seat_type", menu: "ประเภทที่นั่ง", enable: false, editable: false },
                    { key: "vehicle_employee", menu: "ทะเบียนประวัติพนักงานรถ", enable: false, editable: false },
                    { key: "allowance", menu: "เบี้ยเลี้ยง", enable: false, editable: false },
                    { key: "all_car", menu: "รถทั้งหมด", enable: false, editable: false },
                    { key: "vehicle_parking_spot", menu: "จุดจอดยานพาหนะ", enable: false, editable: false },
                    { key: "additional_car", menu: "ค่าใช้จ่ายรถเพิ่มเติม", enable: false, editable: false },
                    { key: "additional_qt", menu: "รายการเพิ่มเติมใน QT", enable: false, editable: false },
                    { key: "purpose_of_travel", menu: "จุดประสงค์การเดินทาง", enable: false, editable: false },
                    { key: "expiry_date_report", menu: "รายงานวันหมดอายุ", enable: false, editable: false },
                ],
				},
				{
					key: "manage_car_parts", 
					menu: "จัดการอะไหล่",
					enable: false,
					editable: false,
                    children: [
                    { key: "parts_type", menu: "ประเภทอะไหล่", enable: false, editable: false },
                    { key: "unit_parts", menu: "หน่วยของอะไหล่", enable: false, editable: false },
                    { key: "manage_sale_vendor", menu: "ผู้ขาย (Vendor)", enable: false, editable: false },
                    { key: "tradesman", menu: "ช่าง", enable: false, editable: false },
                    { key: "manage_spare_parts", menu: "จัดการอะไหล่", enable: false, editable: false },
                    { key: "disbursement_parts", menu: "เบิกจ่ายอะไหล่", enable: false, editable: false },
                    { key: "history_parts", menu: "ประวัติเบิก-ถอนอะไหล่", enable: false, editable: false },
                ],
				},
				{
					key: "manage_repair", 
					menu: "แจ้งซ่อม/อุบัติเหตุ",
					enable: false,
					editable: false,
                    children: [
                    { key: "manage_repair_main", menu: "รายการแจ้งซ่อม", enable: false, editable: false },
                    { key: "repair_type", menu: "ประเภทการซ่อม", enable: false, editable: false },
                    { key: "accident_report", menu: "รายการแจ้งอุบัติเหตุ", enable: false, editable: false },
                ],
				},
				{
					key: "gas_station",
					menu: "ปั๊มน้ำมัน",
					enable: false,
					editable: false,
				},
                { 
                    key: "check_car", 
                    menu: "ตรวจเช็ครถ", 
                    enable: false, 
                    editable: false 
                },
				{
					key: "roll_call",
					menu: "ติดตามรถ",
					enable: false,
					editable: false,
                    children: [
                    { key: "car_tracking", menu: "ติดตามรถ", enable: false, editable: false },
                    { key: "check_car", menu: "ตรวจเช็ครถ", enable: false, editable: false },
                ],
				},
				{
					key: "manage_users",
					menu: "ผู้ใช้งาน",
					enable: false,
					editable: false,
				},
                {
					key: "cr_chat",
					menu: "แชทพนักงานขับรถ",
					enable: false,
					editable: false,
				},				
			],
            detail: {
                id: null,
                name: null,
                contact_name: null,
                phone_no: null,
                address: null,
                photo: null,
                company_id: null
            },
            permissionData: [],
            adminId: [],
        }
    },
    watch: {
        '$route': {
            immediate: true,
            async handler(to, from) {
                if (to.name === 'edit_user_admin') {
                    if (from && from.name !== undefined) {
                        this.loading = true; 
                            await this.get_id_detail();
                    }
                         else {
                            await this.get_id_detail();
                    }
                }
            }
        }
    },
    methods: {
        async onPermissionSave(){       
            var permission = this.menu_permissions.reduce(function(map, obj) {
                    map[obj.key] = !obj.enable ? 0 : obj.editable ? 2 : 1;
                    if (obj.children && obj.children.length > 0) {
                    obj.children.forEach(child => {
                        // Handle child permission
                        map[child.key] = !child.enable ? 0 : child.editable ? 2 : 1;
                    });
                }
                return map;
            }, {});
            if (this.adminId == 1) {
                permission["roll_call"] = permission["check_car"]
            }
                await UserService.update_user(this.$route.params.type, this.permissionData.data.role, 'permission', permission)
            Swal.fire(
                'แก้ไขแอดมินสำเร็จ!',
                'แก้ไขแอดมินสำเร็จแล้ว',
                'success'
            )
            this.$router.go(-1)
        },
        async onRefresh(){
            this.$message.loading({ content: 'กำลังโหลดข้อมูลผู้ใช้...', key: 'init1', duration: 0 })
            await this.init_admin()
            this.$message.success({ content: 'โหลดข้อมูลผู้ใช้เสร็จสิ้น...', key: 'init1', duration: 0.5 })
        },
        async remove_user(id,user){
            Swal.fire({
                title: 'ลบผู้ใช้งาน?',
                text: `คุณต้องการลบผู้ใช้งาน ${user.username} นี้หรือไม่`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await AdminService.deleteAdminUserById(id)
                    this.onRefresh()
                }
            })
        },
        onEnableSwitchChange(v, permission){
            if (!v) {
        // Disable 'editable' for the parent feature
            permission.editable = false;

        // If there are subfeatures, disable them as well
        if (permission.children && permission.children.length > 0) {
            permission.children.forEach(child => {
                child.enable = false;
                child.editable = false;
            });
        }
        } else {
            if (permission.children && permission.children.length > 0) {
                permission.children.forEach(child => {
                    child.enable = true;
                })
            }
        }
        const manageTour = this.menu_permissions.find(p => p.key === "manage_tour");
        const rollCall = this.menu_permissions.find(p => p.key === "roll_call");
        const manageRepair = this.menu_permissions.find(p => p.key === "manage_repair");
        const manageCarParts = this.menu_permissions.find(p => p.key === "manage_car_parts");
        const company = this.menu_permissions.find(p => p.key === "company");
        const billing = this.menu_permissions.find(p => p.key === "billing");
        const quotation = this.menu_permissions.find(p => p.key === "quotation");
        if (manageTour) {
            manageTour.children.forEach(child => {
                if (manageTour.children.every(child => !child.enable)) {
                    manageTour.enable = false;
                    manageTour.editable = false;
                }
                if (child.enable) {
                manageTour.enable = true;
            }
            });
        }
        
        if (rollCall) {
            rollCall.children.forEach(child => {
                if (rollCall.children.every(child => !child.enable)) {
                    rollCall.enable = false;
                    rollCall.editable = false;
                }
                if (child.enable) {
                    rollCall.enable = true;
                }
            });
        }
        if (manageRepair) {
            manageRepair.children.forEach(child => {
                if (manageRepair.children.every(child => !child.enable)) {
                    manageRepair.enable = false;
                    manageRepair.editable = false;
                }
                if (child.enable) {
                    manageRepair.enable = true;
                }
            });
        }
        if (manageCarParts) {
            manageCarParts.children.forEach(child => {
                if (manageCarParts.children.every(child => !child.enable)) {
                    manageCarParts.enable = false;
                    manageCarParts.editable = false;
                }
                if (child.enable) {
                    manageCarParts.enable = true;
                }
            });
        }
        if (company) {
            company.children.forEach(child => {
                if (company.children.every(child => !child.enable)) {
                    company.enable = false;
                    company.editable = false;
                }
                if (child.enable) {
                    company.enable = true;
                }
            });
        }
        if (billing) {
            billing.children.forEach(child => {
                if (billing.children.every(child => !child.enable)) {
                    billing.enable = false;
                    billing.editable = false;
                }
                if (child.enable) {
                    billing.enable = true;
                }
            });
        }
        if (quotation) {
            quotation.children.forEach(child => {
                if (quotation.children.every(child => !child.enable)) {
                    quotation.enable = false;
                    quotation.editable = false;
                }
                if (child.enable) {
                    quotation.enable = true;
                }
            });
        }
        },
        async get_id_detail() {
                this.loading = true;
                if(this.render_type == "edit_user_admin") {
                const id = this.$route.params.type;
                this.permissionData = await AdminService.getPermission(id)
                const admin = await AdminService.getAdminUserById(id)
                this.adminId = admin.data.company.business_type_id
                this.menu_permissions = this.menu_permissions
                        .filter((param) => {
                            if (this.adminId == 2 && param.key === "check_car") {
                                return false;
                            }
                            if (this.adminId == 1 && param.key === "roll_call") {
                                return false;
                            }
                            return true;
                        })
                this.menu_permissions.map((perm) => {
                    perm.enable = this.permissionData.data[perm.key] == 1 || this.permissionData.data[perm.key] == 2 ? true : false
                    perm.editable = this.permissionData.data[perm.key] == 2 ? true : false
                    if (perm.children && perm.children.length > 0) {
                        perm.children = perm.children
                        .filter((child) => {
                            if (this.adminId == 2 && (child.key === "guide" || child.key === "guarantee_work" || child.key === "allowance")) {
                                return false;
                            }
                            if (this.adminId == 1 && child.key === "external_customer") {
                                return false;
                            }
                            return true;
                        })
                        perm.children.map((child) => {
                            child.enable = (this.permissionData.data[child.key] == 1 || this.permissionData.data[child.key] == 2 ? true : false);
                            child.editable = (this.permissionData.data[child.key] == 2 ? true : false);
                        });
                    }this.loading = false;
                    return perm
                })}
        },
        async openUseUser(id) {
            this.loading = true;
            await SystemService.update_all("Admin", { data: { use_user: true } }, id)
            await this.init_admin()
            this.loading = false;
        },
        async closeUseUser(id) {
            this.loading = true;
            await SystemService.update_all("Admin", { data: { use_user: false } }, id)
            await this.init_admin()
            this.loading = false;
        },
        clear_search() {
            this.search_input = null
            this.search_business_type = null
        },
        filtered_list(array) {
            return array.filter(({ username, fullname, business_type_id }) => {
                const searchInputLower = this.search_input ? this.search_input.toLowerCase() : "";
                const searchInput = !this.search_input || 
                    (username && username.toLowerCase().includes(searchInputLower)) ||
                    (fullname && fullname.toLowerCase().includes(searchInputLower));

                const searchBusinessType = !this.search_business_type || !this.search_business_type.value || 
                    business_type_id == this.search_business_type.value;
                return searchInput && searchBusinessType;
            })
        },
        edit_user(id){
            this.$router.push(`/app/admin/${id}/edit`).then(() => {
            window.location.reload();
            });        
        },
        async init_admin () {
            this.loading = true;
            this.show_table_list = await SystemService.get_core('getDataCompanyByAdmin')
            this.loading = false;
        },
        viewUser(id){
            this.$router.push(`/app/admin/${id}`)
        },
    },
    mounted() {
        this.init_admin()
    }
}
</script>

<style>

.permission-table .ant-table-row-level-0 td:first-child,
.permission-table .ant-table-row-level-0 td:nth-child(2),
.permission-table .ant-table-row-level-0 td:nth-child(3) {
    background-color: #f9f9f9 !important;
}
.permission-table .ant-table-row-expand-icon::before,
.permission-table .ant-table-row-expand-icon::after 
{
    content: '' !important;
    border: none !important;
    background: none; /* ปิด background */
    transform: none !important;
    transition: none !important;
    top: auto !important
}
.permission-table .ant-table-row-expand-icon:not(.ant-table-row-expand-icon-expanded)::before {
    content: '>' !important;
}

.permission-table .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded::before {
    content: 'v' !important;
}
</style>