import { layouts } from "chart.js";

let fleet_and_sim_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        sub_divider_3_0: {
            input_type: 'divider',
            label: 'Fleet oil & Sim GPS',
            col_span: 23
        },
        oil_1_1: {
            input_type: 'text',
            placeholder: 'ประเภทปั๊ม',
            col_span: 7
        },
        oil_1_2: {
            input_type: 'text',
            placeholder: 'Fleetcard',
            col_span: 7
        },
        oil_1_3: {
            input_type: 'text',
            placeholder: 'GPS simcard',
            col_span: 7
        },


        documents: {
            input_type: 'sub-divider',
            label: 'เอกสารทะเบียนครบชุด',
            col_span: 23
        },
        doc_1: {
            input_type: 'file',
            label: 'ทะเบียนรถ/การต่อภาษี',
            col_span: 10,
            layout: 'vertical'
        },
        doc_2: {
            input_type: 'file',
            label: 'พ.ร.บ.',
            col_span: 10,
            layout: 'vertical'
        },
        doc_3: {
            input_type: 'file',
            label: 'ประกันภัยประเภท 3',
            col_span: 10,
            layout: 'vertical',
        },
        doc_4: {
            input_type: 'file',
            label: 'หนังสือรับรอง GPS',
            col_span: 10,
            layout: 'vertical'
        },
        doc_5: {
            input_type: 'file',
            label: 'ใบขับขี่',
            col_span: 10,
            layout: 'vertical'
        },
        doc_6: {
            input_type: 'file',
            label: 'ครบชุด pdf',
            col_span: 10,
            layout: 'vertical'
        },
    }
};

export default fleet_and_sim_fields