import httpClient from './httpClient';
const prefix = 'admin'

export default {
  
    async createCompanyAdmin(body) {
      const { data } = await httpClient({ requiresAuth: true })
        .post(`${prefix}/createCompanyAdmin`, {
          body
        })
  
      return data
    },
  
    async createAdmin(body) {
      const { data } = await httpClient({ requiresAuth: true })
        .post(`${prefix}/createAdmin`, {
          body
        })
  
      return data
    },
  
    async createPermissionAdmin(body) {
      const { data } = await httpClient({ requiresAuth: true })
        .post(`${prefix}/createPermissionAdmin`, {
          body
        })
  
      return data
    },

    async getPermission(role_id) {
      const { data } = await httpClient({ requiresAuth: true })
        .get(`core/getPermissionByAdmin?role_id=${role_id}`)
      return data
    },

    async getAdminUserById(id) {
      const { data } = await httpClient({ requiresAuth: true })
        .get(`core/getAdminUserById?id=${id}`)
      return data
    },

    async deleteAdminUserById(id) {
      const { data } = await httpClient({ requiresAuth: true })
        .delete(`core/deleteAdminUserById?id=${id}`)
      return data
    },
}