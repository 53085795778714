import { layouts } from "chart.js";

let registration_and_tax_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_3: {
            input_type: 'divider',
            label: 'ข้อมูลด้านทะเบียน / ชำระภาษี',
            col_span: 23
        },
        car_register_date: {
            input_type: 'date', 
            placeholder: 'วันที่จดทะเบียน',
            // required: true,
            required: false,
            col_span: 7
        },
        car_tax_rate: {
            input_type: 'number',
            placeholder: 'อัตราภาษี',
            // required: true,
            required: false,
            col_span: 7
        },
        car_appearance: {
            input_type: 'text',
            placeholder: 'ลักษณะ/มาตรฐานรถ',
            // required: true,
            required: false,
            col_span: 7
        },
        car_inspect_1: {
            input_type: 'month',
            placeholder: 'รอบตรวจสภาพ 1',
            // required: true,
            required: false,
            col_span: 10
        },
        car_inspect_2: {
            input_type: 'month',
            placeholder: 'รอบตรวจสภาพ 2',
            // required: true,
            required: false,
            col_span: 10
        },
    }
};

export default registration_and_tax_fields