<template>
    <div>
        <a-modal v-model:visible="show_create_client" title="เพิ่มข้อมูลลูกค้า" :afterClose="afterClose" :footer="null">
            <a-form
                :model="create_contact"
                name="create_contact" layout="vertical"
                autocomplete="off"
                @finish="create_client_contact"
            >
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อบริษัท/ลูกค้า" name="client_id" :rules="[{ required: true, message: 'โปรดเลือกบริษัท'}]">
                            <a-select
                                v-model:value="create_contact.client_id"
                                show-search
                                placeholder="เลือกบริษัท"
                                :options="client_company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                                :filter-option="filterOption"
                                @change="select_client"
                                disabled
                            ></a-select>
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="สาขา">
                            <a-select v-model:value="create_contact.client_branch_id" disabled  placeholder="เลือกสาขา">
                                <a-select-option v-for="{ id, name } in client_branches_list" :key="id" :value="id">
                                    {{name}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    
                    <a-col>
                        <a-form-item label="ชื่อ" name="firstname">
                            <a-input v-model:value="create_contact.firstname" placeholder="ชื่อ" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="นามสกุล" name="lastname" >
                            <a-input v-model:value="create_contact.lastname" placeholder="นามสกุล" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="เบอร์โทร" name="phone_no">
                            <a-input v-model:value="create_contact.phone_no" placeholder="เบอร์โทร" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="อีเมล" name="email" >
                            <a-input v-model:value="create_contact.email" placeholder="อีเมล" />
                        </a-form-item>
                    </a-col>

                    <!-- <a-col>
                        <a-form-item label="Username" name="username" >
                            <a-input v-model:value="create_contact.username" placeholder="Username" />
                        </a-form-item>
                    </a-col>

                    <a-col>
                        <a-form-item label="Password" name="password" >
                            <a-input v-model:value="create_contact.password" placeholder="password" type="password" />
                        </a-form-item>
                    </a-col> -->

                </a-row>

                <a-row justify="end">
                    <a-col :span="6">
                        <a-button html-type="submit" type="primary" style="display: flex;margin: 1.25rem 1rem;text-align: right;" size="large">
                            <template #icon>
                                <span class="material-symbols-outlined">Add</span>
                            </template>
                            เพิ่มข้อมูล
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </a-modal>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'

export default {
    props: {
        show_create_client: Boolean,
        client_company_list: Array,
        selectedClient_id: Number,
        selectedBranch_id: Number,
    },
    name: 'Create Client',
    data () {
        return {
            client_branches_list: [],
            
            create_contact: {
                client_branch_id: null,
                client_id: null,
                email: null,
                firstname: null,
                lastname: null,
                password: null,
                phone_no: null,
                username: null   
            }
        }
    },
    methods: {
        filterOption (input, option) {
            return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
        },
        async create_client_contact () {
            this.create_contact.company_id = JSON.parse(localStorage.getItem('companyData')).id
            
            await SystemService.create_all('ClientContact', { data: [ this.create_contact ]})
                .then(async res => {
                    const contact_id = res.data?.id
                    if (contact_id) {
                        Swal.fire({
                            icon: 'success',
                            title: 'เพิ่มข้อมูลสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$emit('onCreated', true)
                        this.close_modal()
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        close_modal() {
            document.getElementsByClassName('ant-modal-close-x')[0].click()
        },
        clear_inputs() {
            this.create_contact = {}
        },
        afterClose() {
            this.clear_inputs()
            this.$emit('afterClose', true)
        },
        async select_client () {
            this.create_contact.client_branch_id = null
            const target = await SystemService.get_id('Client', this.create_contact.client_id) 
            const { branches } = target

            this.client_branches_list = branches

        },
    },
    watch: {
        show_create_client: function (val) {
            if (!val) {
                this.clear_inputs()
            } else {
                if (this.selectedClient_id) {
                    this.create_contact.client_id = this.selectedClient_id
                    this.select_client()
                }
                if (this.selectedBranch_id){
                    this.create_contact.client_branch_id = this.selectedBranch_id

                }
            }
        },
        selectedClient_id: function (val) {
            this.create_contact.client_id = val
        },
        selectedBranch_id: function (val){
            this.create_contact.client_branch_id = val
        }
    }
}
</script>

<style scoped>
.ant-col-12 {
    min-width: 300px;
    padding: 4px
}
.ant-select, .ant-picker {
    width: 100%
}
.ant-col {
    width: 100%;
    margin: 8px 0
}
.ant-form-item {
    margin-bottom: 0;
}
.quotation-container {
    padding: 15px;
    height: 100vh;
}</style>