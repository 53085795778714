<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card :title="topic[render_type].title">
                <template v-if="!sjinda" #extra>
                    <a-space>
                        <div v-if="editable"> 
                            <a-button v-if="topic[render_type].button_title" style="display: flex"
                                type="primary" size="large" @click="button_on(render_type)">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                {{ topic[render_type].button_title }}
                            </a-button>
                        </div>

                        <a-dropdown v-if="topic[render_type].allow_import">
                            <template #overlay>
                                <a-menu >
                                    <a-menu-item key="1">
                                        <span class="material-symbols-outlined">description</span>
                                        import
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <span class="material-symbols-outlined">description</span>
                                        Export
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex;">
                                <span class="material-symbols-outlined">Settings</span>
                                ตัวเลือก
                                <span class="material-symbols-outlined">expand_more</span>
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>

                <a-row v-if="sjinda" style="display: flex; justify-content: flex-end;">
                    <a-space>
                        <div v-if="editable"> 
                            <a-button v-if="topic[render_type].button_title" style="display: flex"
                                type="primary" size="large" @click="button_on(render_type)">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                {{ topic[render_type].button_title }}
                            </a-button>
                        </div>
                        <a-dropdown v-if="topic[render_type].allow_import">
                            <template #overlay>
                                <a-menu >
                                    <a-menu-item key="1">
                                        <span class="material-symbols-outlined">description</span>
                                        import
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <span class="material-symbols-outlined">description</span>
                                        Export
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex;">
                                <span class="material-symbols-outlined">Settings</span>
                                ตัวเลือก
                                <span class="material-symbols-outlined">expand_more</span>
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </a-row>
                <br v-if="topic[render_type].button_title && sjinda">
                <a-row>
                    <a-space>
                        <a-input-search v-if="topic[render_type].input" v-model:value="search_input"
                            :placeholder="`ค้นหา ${topic[render_type].input}`" enter-button="ค้นหา" />
                        <a-range-picker v-if="topic[render_type].datepicker" v-model:value="datetime" />
                        <a-select v-if="topic[render_type].select_stock" style="width: 100%" v-model:value="selected_type" placeholder="เลือกประเภทการเบิก">
                            <a-select-option v-for="{ text, value }, i in [
                                { text: 'เบิกเข้า', value: 1 },
                                { text: 'เบิกออก', value: 2 },
                            ]" :key="i" :value="value">{{ text }}
                            </a-select-option>
                        </a-select>
                        <a-select v-if="topic[render_type].select_status" style="width: 100%" v-model:value="selected_expire_type" placeholder="เลือกประเภทวันหมดอายุ">
                            <a-select-option v-for="{ text, value }, i in [
                                { text: 'ใหม่', value: 1 },
                                { text: 'อนุมัต', value: 2 },
                                { text: 'ยกเลิก', value: 3 },
                            ]" :key="i" :value="value">{{ text }}
                            </a-select-option>
                        </a-select>
                        <a-button v-if="topic[render_type].clear" type="dashed" @click="clear_search">CLEAR</a-button>
                    </a-space>

                </a-row>

                <a-row>
                    <a-space>
                        <a-button v-for="button, i in topic[render_type].lower_buttons" :key="i" style="display: flex"
                            type="primary" size="large" @click="button_action(button.name)">
                            {{ button.title }}
                        </a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="topic[render_type].columns.filter((c) => !editable? c.key != 'id': true)" :data-source="filtered_list(show_table_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'withdraw_type'">
                                <span><a-tag :color="record.withdraw_type.color|| 'default'">
                                    {{record.withdraw_type?.name || '-'}}
                                </a-tag></span>
                            </template>
                            <template v-if="column.key === 'withdraw_status'">
                                <span><a-tag :color="record.withdraw_status.color || 'default'">
                                    {{record.withdraw_status?.name || '-' }}
                                </a-tag></span>
                            </template>
                            <template v-if="column.key === 'withdrawed_at'">
                                {{render_date(record.withdrawed_at) || '-' }}
                            </template>
                            <template v-if="column.key === 'stock_withdrawed_at'">
                                {{render_date(record.stock_history?.withdrawed_at) || '-' }}
                            </template>
                            <template v-if="column.key === 'fullname'">
                                {{ record.firstname && record.lastname ? `${record.firstname} ${record.lastname}` : '-' }}
                            </template>
                            <template v-if="column.key === 'part_barcode'">
                                        {{record.part?.barcode || '-' }}
                            </template>
                            <template v-if="column.key === 'part_name'">
                                        {{record.part?.name || '-' }}
                            </template>
                            <template v-if="column.key === 'stock_remark'">
                                        {{record.stock_history?.remark || '-' }}
                            </template>
                            <template v-if="column.key === 'stock_refno'">
                                        {{record.stock_history.refno || '-' }}
                            </template>
                            <template v-if="column.key === 'part_type'">
                                        {{record.part_type?.name || '-' }}
                            </template>
                            <template v-if="column.key === 'part_unit'">
                                        {{record.part_unit?.name || '-' }}
                            </template>
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a v-if="record.modelname=='StockHistory'" @click="go_show_modal_status(record, record.modelname)"><span style="font-size: 18px" class="material-symbols-outlined">trending_up</span></a>
                                    <a-divider v-if="record.modelname=='StockHistory'" type="vertical"></a-divider>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
        <a-modal v-model:visible="show_modal" title="เลือกสถานะของการเบิก-จ่ายอะไหล่" ok-text="ตกลง" cancel-text="ปิด" @ok="save_status">
            <a-row type="flex" style="flex-direction: column">
                <a-form-item label="สถานะ" style="margin: 0;"/>
                <a-col flex="1 0">
                    <a-select style="width: 100%" v-model:value="selected_stock_status" placeholder="เลือกสถานะ"
                        :dropdownMatchSelectWidth="false">
                        <a-select-option v-for="{ id, name } in withdraw_status_list" :key="id" :value="id">{{
                                name
                        }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>

<script>
import VehicleService from '../../api/VehicleService'
import UserService from '../../api/UserService'
import Utility from '../../utility'
import SystemService from '../../api/SystemService'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import CompanyService from '../../api/CompanyService'
dayjs.extend(buddhistEra)
export default {
    name: 'autoparts',
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('manage_car_parts'),
            company_list: [],
            sjinda: false,
            show_table_list: [],
            search_input: null,
            datetime: null,
            selected_type: null,
            selected_expire_type: null,
            show_modal: false,

            /*  update withdraw status*/
            withdraw_status_list: [],
            selected_stock_id: null,
            selected_stock_status: null,

            topic: {
                PartType: {
                    title: 'จัดการประเภทอะไหล่',
                    button_title: 'เพิ่มประเภทอะไหล่',
                    input: 'ประเภทอะไหล่',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อประเภทอะไหล่',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 670,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ],
                },
                PartUnit: {
                    title: 'จัดการหน่วยอะไหล่',
                    button_title: 'เพิ่มหน่วยอะไหล่',
                    input: 'ชื่อหน่วยอะไหล่',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อหน่วยอะไหล่',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 670,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Vendor: {
                    title: 'จัดการผู้ขาย (Vendor)',
                    button_title: 'เพิ่มผู้ขาย (Vendor)',
                    input: 'ชื่อผู้ขาย (Vendor)',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อผู้ขาย (Vendor)',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 300,
                            align: 'center',
                        }, {
                            title: 'ชื่อผู้ติดต่อ',
                            dataIndex: 'contact_name',
                            key: 'contact_name',
                            width: 250,
                            align: 'center',
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ที่อยู่',
                            dataIndex: 'address',
                            key: 'address',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Technician: {
                    title: 'จัดการช่าง (Technician)',
                    button_title: 'เพิ่มช่าง (Technician)',
                    input: 'ชื่อช่าง (Technician)',
                    clear: true,
                    allow_import: false,
                    columns: [
                        {
                            title: 'ช่าง (Technician)',
                            dataIndex: 'fullname',
                            key: 'fullname',
                            sorter: true,
                            width: 400,
                            align: 'center',
                        }, {
                            title: 'ชื่อเล่น',
                            dataIndex: 'nickname',
                            key: 'nickname',
                            sorter: true,
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'อีเมล',
                            dataIndex: 'email',
                            key: 'email',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'เบอร์สำรอง',
                            dataIndex: 'phone_backup',
                            key: 'phone_backup',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Part: {
                    title: 'จัดการอะไหล่',
                    button_title: 'เพิ่มอะไหล่',
                    input: 'รหัสอะไหล่',
                    clear: true,
                    columns: [
                        {
                            title: 'รหัส',
                            dataIndex: 'barcode',
                            key: 'barcode',
                            sorter: true,
                            width: 300,
                            align: 'center',
                        }, {
                            title: 'ประเภทอะไหล่',
                            dataIndex: 'part_type',
                            key: 'part_type',
                            sorter: true,
                            width: 250,
                            align: 'center',
                        }, {
                            title: 'ชื่ออะไหล่',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'จำนวน',
                            dataIndex: 'amount',
                            key: 'amount',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'หน่วย',
                            dataIndex: 'part_unit',
                            key: 'part_unit',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ราคา',
                            dataIndex: 'price',
                            key: 'price',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                StockHistory: {
                    title: 'รายการเบิก - จ่ายอะไหล่',
                    button_title: 'เพิ่ม - ลดอะไหล่',
                    input: 'Stock No',
                    select_stock: true,
                    select_status: true,
                    datepicker: true,
                    clear: true,
                    columns: [
                        {
                            title: 'เลขที่รายการ',
                            dataIndex: 'refno',
                            key: 'refno',
                            sorter: true,
                            align: 'center',
                        }, {
                            title: 'ประเภท',
                            dataIndex: 'withdraw_type',
                            key: 'withdraw_type',
                            sorter: true,
                            align: 'center',
                        }, {
                            title: 'วันที่เบิก',
                            dataIndex: 'withdrawed_at',
                            key: 'withdrawed_at',
                            align: 'center',
                        }, {
                            title: 'สถานะ',
                            dataIndex: 'withdraw_status',
                            key: 'withdraw_status',
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 125,
                            align: 'center',
                            fixed: 'right',
                            align: 'center',
                        }
                    ]
                },
                PartHistory: {
                    title: 'ประวัติรายการเบิก - ถอนอะไหล่ (Stock Movement)',
                    input: 'ชื่ออะไหล่',
                    datepicker: true,
                    clear: true,
                    columns: [
                        {
                            title: 'วันที่',
                            dataIndex: 'stock_withdrawed_at',
                            key: 'stock_withdrawed_at',
                            width: 170,
                            sorter: true,
                            align: 'center',
                        }, {
                            title: 'เลขอะไหล่',
                            dataIndex: 'part_barcode',
                            key: 'part_barcode',
                            width: 250,
                            sorter: true,
                            align: 'center',
                        }, {
                            title: 'ชื่ออะไหล่',
                            dataIndex: 'part_name',
                            key: 'part_name',
                            width: 250,
                            align: 'center',
                        }, {
                            title: 'จำนวน',
                            dataIndex: 'amount',
                            key: 'amount',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'หมายเหตุ',
                            dataIndex: 'stock_remark',
                            key: 'stock_remark',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'Ref NO',
                            dataIndex: 'stock_refno',
                            key: 'stock_refno',
                            width: 170,
                            align: 'center',
                        }
                    ]
                }
            }
        }
    },
    computed: {
        render_type() {
            return this.$route.params.type
        }
    },
    methods: {
        render_date(date){
            return dayjs(date).format('DD-MM-YYYY')
        },
        clear_search(){
            this.search_input = null
            this.datetime = null
            this.selected_type = null
            this.selected_expire_type= null
        },
        filtered_list(array) {
            return array.filter(({ withdrawed_at, stock_history })=>{
                if(this.datetime){
                    var startDate = this.datetime[0]
                    var endDate = this.datetime[1]

                    var startDateUnix = dayjs(startDate).valueOf()
                    var endDateUnix = dayjs(endDate).valueOf()

                    if(stock_history){
                        var targetDate = dayjs(stock_history.withdrawed_at)
                        return  startDateUnix <= dayjs(targetDate).valueOf() && dayjs(targetDate).valueOf() <= endDateUnix
                    }else{
                        var targetDate = dayjs(withdrawed_at)
                        return  startDateUnix <= dayjs(targetDate).valueOf() && dayjs(targetDate).valueOf() <= endDateUnix
                    }
                }else{
                    return true
                }
            })
            .filter(({ withdraw_type_id })=>{
                if(this.selected_type){
                    return this.selected_type == withdraw_type_id
                }else{
                    return true
                }
            })
            .filter(({ withdraw_status_id })=>{
                if(this.selected_expire_type){
                    return this.selected_expire_type == withdraw_status_id
                }else{
                    return true
                }
            })
            .filter(({ name, firstname, lastname, refno, part}) => {
                if(this.search_input){
                    if(firstname && lastname){
                        return firstname.toLowerCase().includes(this.search_input.toLowerCase()) ||
                        lastname.toLowerCase().includes(this.search_input.toLowerCase()) 
                    }else if(refno){
                        return refno.toLowerCase().includes(this.search_input.toLowerCase())
                    }else if(part){
                        return part.name.toLowerCase().includes(this.search_input.toLowerCase())
                    }else{
                        return name.toLowerCase().includes(this.search_input.toLowerCase())
                    }
                } else {
                    return true
                }
            })
        },
        async init_data_table(type) {
            const data = await SystemService.get_all(type)
            this.show_table_list = data
        },
        button_on(type){
            this.$router.push(`/app/${type}/create`)
        },
        async init_company_default() {
            this.company_list = await SystemService.get_all('CarCompany')
            const businessType = await CompanyService.getBusinessId(this.company_list[0].company_id)
            if (this.company_list[0].company_id === 39 || businessType.data === 2){
                this.sjinda = true
            }
        },
        button_action(type) {
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    this.init_data_table(type)
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        go_edit(model_data, modelname) {
            var title_name = {
                PartType: 'name',
                PartUnit: 'name',
                Vendor: 'name',
                Technician: 'firstname',
                Part: 'name',
                StockHistory: 'refno',
            }
            this.$router.push(`/app/${modelname}/${model_data.id}?title_name_key=${title_name[modelname]}`)
        },
        go_show_modal_status(data, modelname){
            this.show_modal = true
            this.selected_stock_id = data.id
            this.selected_stock_status = data.withdraw_status_id
        },
        async save_status(){
            await SystemService.update_all('StockHistory',{data : {withdraw_status_id: this.selected_stock_status}}, this.selected_stock_id)
            this.selected_stock_status = null
            this.show_modal = false
            Swal.fire({
                title: 'บันทึกอะไหร่สำเร็จ',
                icon: 'success',
                timer: 1000,
                timerProgressBar: false,
                showConfirmButton: false,
            })
            this.init_data_table(this.render_type)
        },
        async get_default_data() {
            if(this.render_type == 'StockHistory') this.withdraw_status_list = await SystemService.get_all('WithdrawStatus')
        }
    },
    watch: {
        async render_type(newVal) {
            if (newVal) {
                this.show_table_list = []
                await this.init_data_table(this.render_type)
            }
        }
    },
    async mounted() {
        this.init_company_default()
        this.init_data_table(this.render_type)
        await this.get_default_data()
    }
}
</script>

<style>
.ant-modal-footer{
    display: flex;
    justify-content: flex-end;
}
.ant-table-thead .center-header {
  text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
}
</style>