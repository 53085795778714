import httpClient from './httpClient';
 
const prefix = 'bank'
 
export default {
  async getBankById(id) {
    const data = await httpClient({ requiresAuth: true }).get(`${prefix}/getBankById/${id}`);
    return data;
  },
  async getAll() {
    const {data} = await httpClient({ requiresAuth: true }).get(`${prefix}/getAll`);
    return data;
  },
  async getAllBankBranches() {
    const {data} = await httpClient({ requiresAuth: true }).get(`${prefix}/getAllBankBranches`);
    return data;
  },
}
 