import { layouts } from "chart.js";

let option_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_5: {
            input_type: 'divider',
            label: 'ข้อมูลออฟชั่นรถ',
            col_span: 23
        },
        create_options: {
            input_type: 'checkbox',
            placeholder: 'ออฟชั่นรถ',
            required: false,
            modelname: 'Option',
            label_key: 'name',
            col_span: 23
        },
    }
};

export default option_fields