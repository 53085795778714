import { layouts } from "chart.js";

let insure_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_2: {
            input_type: 'divider',
            label: 'ข้อมูลด้านประกันภัย',
            col_span: 23
        },
        tax_label: {
            input_type: 'label',
            label: 'พ.ร.บ.',
            col_span: 23,
        },
        tax_provider_name: {
            input_type: 'text',
            placeholder: 'บริษัทประกันภัย',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_no: {
            input_type: 'text',
            placeholder: 'เลขที่กรมธรรม์',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_start: {
            input_type: 'date',
            placeholder: 'วันเริ่ม',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_expire: {
            input_type: 'date',
            placeholder: 'วันสิ้นสุด',
            // required: true,
            required: false,
            col_span: 11    
            },
        car_tax_premium_net: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิ',
            // required: true,
            required: false,
            col_span: 11
                },
        car_tax_premium_total_added: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร',
            // required: true,
            required: false,
            col_span: 11     
           },
        car_tax_stamps: {
            input_type: 'text',
            placeholder: 'อากรแสตมป์',
            // required: true,
            required: false,
            col_span: 11     
           },
        car_tax_vat: {
            input_type: 'number',
            placeholder: 'ภาษีมูลค่าเพิ่ม',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_total: {
            input_type: 'number',
            placeholder: 'ยอดสุทธิ',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_label: {
            input_type: 'label',
            label: 'ภาคสมัครใจ',
            col_span: 23,
        },
        insure_provider_name: {
            input_type: 'text',
            placeholder: 'บริษัทประกันภัย',
            // required: true,
            required: false,
            col_span: 11
                },
        insure_no: {
            input_type: 'text',
            placeholder: 'เลขที่กรมธรรม์',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_start: {
            input_type: 'date',
            placeholder: 'วันเริ่ม',
            format: 'DD/MM/YYYY',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_expire: {
            input_type: 'date',
            placeholder: 'วันสิ้นสุด',
            required: false,
            col_span: 11
        },
        insure_premium_net: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิ',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_premium_total_added: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_stamps: {
            input_type: 'text',
            placeholder: 'อากรแสตมป์',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_vat: {
            input_type: 'number',
            placeholder: 'ภาษีมูลค่าเพิ่ม',
            // required: true,
            required: false,
            col_span: 11
        },
        maintenance_expire: {
            input_type: 'date',
            placeholder: 'วันตรวจสภาพรถประจำปี',
            // required: true,
            required: false,
            col_span: 11
        },
        repair_target: {
            input_type: 'number',
            placeholder: 'เลขไมล์ซ่อมถัดไป',
            required: false,
            col_span: 11
        },
        insure_total: {
            input_type: 'number',
            placeholder: 'ยอดสุทธิ',
            // required: true,
            required: false,
            col_span: 11
        },
    }
};

export default insure_fields